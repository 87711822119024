
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const department: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.DEPARTMENT_LIST);
    const departmentList = computed(
      () => store.state.DepartmentModule.department.value || []
    );

    const onChange = (args) => {
      emit("changeDepartment",args);
    };
    return { close, department, store, departmentList, onChange };
  },
};
